import { useBooleanFeatureFlag } from '@confluence/session-data';

import { WHITEBOARD_AND_DATABASE_CLASSIFICATION_FF } from '../constants/FeatureFlags';

import { useIsDataClassificationEnabledForOrg } from './useIsDataClassificationEnabledForOrg';

const isContentTypeApplicable = (contentType: string, isLivePage: boolean = false) => {
	if (contentType === 'page' && isLivePage) return true;
	return ['whiteboard', 'database'].includes(contentType);
};

export const useIsClassificationEnabledForContentTypeExpansion = (
	contentType: string,
	isLivePage: boolean = false,
): boolean => {
	const { isEnabled: isClassificationEnabledForOrg } = useIsDataClassificationEnabledForOrg();

	const isWhiteboardDbClassificationsEnabled = useBooleanFeatureFlag(
		WHITEBOARD_AND_DATABASE_CLASSIFICATION_FF,
	);

	return (
		isContentTypeApplicable(contentType, isLivePage) &&
		isClassificationEnabledForOrg &&
		isWhiteboardDbClassificationsEnabled
	);
};
